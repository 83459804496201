<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>SKU Configurations</h3>
    </header>
    <sku-configurations-edit></sku-configurations-edit>
  </div>
</template>

<script>
import SKUConfigurationsEdit from '../../../components/OrderManagement/SkuConfigs/SKUConfigurationsEdit.vue';


export default {
  name: 'SkuConfigsEdit.vue',
  components: {
    'sku-configurations-edit': SKUConfigurationsEdit,
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
